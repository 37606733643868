:root {
    --primary-color: #E8FD86;
    --secondary-color: #190905;
    --tertiary-color: #243F3B;
    --txt-color: black;
    --light-txt-color: #E6F4D1;

    --primary-font: "Inter Tight", sans-serif;
    --secondary-font: "PT Serif", serif;
}

body {
    margin: 0;
    font-family: var(--primary-font);
    font-size: 100%;
    font-weight: 400;
    @media (max-width: 991px) {
        background-color: var(--tertiary-color);
    }
    &.open-menu {
        overflow: hidden;
    }
    &.cpragma-home {
        .main-header {
            .row-header {
                &__col {
                    &--logo {
                        svg.logo path {
                            fill: var(--primary-color);
                        }
                    }
                    &--menu {
                        .nav-menu {
                            &__item {
                                a {
                                    color: var(--primary-color);
                                    &:after {
                                        background-color: var(--primary-color);
                                    }
                                }
                                &--border {
                                    a {
                                        border: 1px solid var(--primary-color);
                                        @media (max-width: 991px) {
                                            color: var(--secondary-color);
                                        }
                                        &:hover {
                                            background-color: var(--primary-color);
                                            color: black;
                                        }
                                    }
                                }
                                &--last {
                                    a {
                                        svg path {
                                            fill: var(--primary-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

::selection {
    background-color: var(--tertiary-color);
    color: var(--primary-color);
}

a {
    text-decoration: none;
    transition: .3s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.lity {
    background: rgba(0,0,0,.8);
    .lity-wrap {
        .lity-container {
            max-width: 80%;
            @media (max-width: 767px) {
                max-width: 90%;
            }
            .lity-iframe-container {
                @media (max-width: 767px) {
                    height: 768px;
                }
            }
        }
    }
}

header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    .pre-header {
        position: relative;
        padding: .75em 0;
        background-color: var(--secondary-color);
        z-index: 1;
        @media (max-width: 991px) {
            padding: .35em 0;
        }
        .btn-customer {
            display: flex;
            justify-content: flex-end;
            a {
                display: flex;
                align-items: center;
                font-size: 19px;
                color: var(--light-txt-color);
                @media (max-width: 991px) {
                    font-size: 16px;
                }
                span {
                    margin-right: 10px;
                }
                span, img {
                    display: block;
                }
            }
        }
    }
    .main-header {
        padding: 2em 0;
        @media (max-width: 991px) {
            padding: 1em 0;
        }
        .row-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__col {
                &--logo {
                    .logo {
                        @media (max-width: 991px) {
                            width: 280px;
                        }
                    }
                }
                &--menu {
                    .btn-colapse-xs {
                        position: relative;
                        width: 30px;
                        height: 21px;
                        flex-direction: column;
                        justify-content: space-between;
                        display: none;
                        cursor: pointer;
                        @media (max-width: 991px) {
                            display: flex;
                        }
                        .line {
                            display: block;
                            width: 30px;
                            height: 3px;
                            background-color: var(--primary-color);
                            border-radius: 50px;
                            transition: .3s;
                        }
                        &.open {
                            justify-content: center;
                            align-items: center;
                            .line {
                                position: absolute;
                                &--l1 {
                                    transform: rotate(45deg);
                                }
                                &--l2 {
                                    opacity: 0;
                                }
                                &--l3 {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                    .nav-menu {
                        display: flex;
                        align-items: center;
                        @media (max-width: 991px) {
                            display: none;
                            position: fixed;
                            width: 100%;
                            height: 100vh;
                            padding: 1em 15px 0;
                            background-color: transparent;
                            top: 104px;
                            left: 0;
                            z-index: 200;
                            overflow: auto;
                        }
                        &__item {
                            margin-left: 2.5em;
                            @media (max-width: 991px) {
                                margin-left: 0;
                                padding: 1em 0;
                            }
                            &:not(:last-child) {
                                @media (max-width: 991px) {
                                    border-bottom: 1px solid rgba(232, 253, 134, .42);
                                }
                            }
                            a {
                                position: relative;
                                display: block;
                                padding: .1em 0;
                                font-size: 22px;
                                z-index: 2;
                                overflow: hidden;
                                @media (max-width: 1199px) {
                                    font-size: 18px;
                                }
                                @media (max-width: 991px) {
                                    font-size: 20px;
                                    padding: 0;
                                }
                                img {
                                    display: block;
                                }
                                &:after {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 4px;
                                    bottom: -4px;
                                    opacity: 0;
                                    left: 0;
                                    transition: .3s;
                                    @media (max-width: 991px) {
                                        display: none;
                                    }
                                }
                                &:hover {
                                    &:after {
                                        bottom: 0;
                                        opacity: 1;
                                    }
                                }
                            }
                            &--border {
                                a {
                                    padding: .25em .875em;
                                    border-radius: 20px;
                                    @media (max-width: 991px) {
                                        display: inline-block;
                                        border: none;
                                        padding: .15em 1em;
                                        background-color: var(--primary-color);
                                        color: var(--secondary-color);
                                    }
                                    &:after {
                                        display: none;
                                    }
                                    &:hover {
                                        font-family: var(--primary-font);
                                        font-style: normal;
                                    }
                                }
                            }
                            &--last {
                                a {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

footer {
    position: relative;
    padding: 3em 0;
    text-align: center;
    background-color: var(--primary-color);
    @media (max-width: 991px) {
        padding: 3em 15px;
    }
    h1 {
        font-size: 39px;
        color: var(--secondary-color);
        @media (max-width: 991px) {
            font-size: 28px;
        }
        span {
            font-family: var(--secondary-font);
            font-style: italic;
        }
    }
    .footer-row {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin: 32px 0 0;
        flex-wrap: wrap;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        &__btn {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--secondary-color);
                border-radius: 50px;
                width: 350px;
                height: 55px;
                font-size: 24px;
                color: var(--secondary-color);
                @media (max-width: 991px) {
                    width: 100%;
                }
                &:hover {
                    background-color: var(--secondary-color);
                    color: white;
                }
            }
        }
    }
}

.main-content {
    transition: .5s;
    &.blur {
        filter: blur(100px);
    }
    .hero-bg {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        background-size: 2000px;
        background-position: center;
        background-repeat: no-repeat;
        .container {
            position: absolute;
            left: 0;
            right: 0;
        }
        .hero-content {
            &__title {
                width: 866px;
                font-size: 58px;
                color: var(--primary-color);
                font-weight: 400;
                @media (max-width: 1199px) {
                    width: 65%;
                    font-size: 42px;
                }
                @media (max-width: 991px) {
                    width: 100%;
                    font-size: 38px;
                }
                span {
                    font-family: var(--secondary-font);
                    font-style: italic;
                }
            }
        }
        &__btn-down {
            position: absolute;
            right: 50px;
            bottom: 50px;
            display: block;
            cursor: pointer;
            @media (max-width: 991px) {
                right: 20px;
                bottom: 115px;
            }
            img {
                display: block;
                transition: .3s;
                @media (max-width: 991px) {
                    width: 70px;
                }
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .logo-animation {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3em 0;
        background-color: var(--tertiary-color);
        overflow: hidden;
        .logo-animation-list {
            display: inline-block;
            transition: .3s;
        }
        .a-pragma {
            margin-left: 10px;
            padding-top: 5px;
            transition: .3s;
        }
    }

    .txt-top-section {
        margin: 0 0 3em;
        @media (max-width: 767px) {
            margin: 0 0 2em;
        }
        span {
            display: block;
            font-size: 22px;
            font-style: italic;
            font-family: var(--secondary-font);
        }
        &--light {
            span {
                color: black;
            }
        }
        &--dark {
            span {
                color: var(--light-txt-color);
            }
        }
    }

    .title-section {
        width: 40%;
        margin: 0 0 3em;
        @media (max-width: 1199px) {
            width: auto;
        }
        @media (max-width: 767px) {
            margin: 0 0 2em;
        }
        h2 {
            margin: 0;
            font-weight: 400;
            span {
                font-family: var(--secondary-font);
                font-style: italic;
            }
        }
        &__subhead {
            margin: 2em 0 0;
            p {
                font-size: 20px;
                @media (max-width: 1199px) {
                    font-size: 18px;
                }
            }
        }
        &--light {
            h2 {
                font-size: 39px;
                color: black;
            }
            .title-section {
                &__subhead {
                    p {
                        color: black;
                    }
                }
            }
        }
        &--dark {
            h2 {
                font-size: 54px;
                color: var(--light-txt-color);
                @media (max-width: 1199px) {
                    font-size: 39px;
                }
            }
            .title-section {
                &__subhead {
                    p {
                        color: var(--light-txt-color);
                    }
                }
            }
        }
    }

    .row-gobles {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5em -4px 0;
        padding-left: calc(98px);
        @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
            margin: 3em -4px 0;
        }
        .gobe-circle {
            position: absolute;
            width: 94px;
            height: 94px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            top: 0;
            left: 0;
            img {
                display: block;
            }
        }
        &__txt {
            padding: 0 4px;
            @media (max-width: 991px) {
                width: 100%;
                margin-bottom: 10px;
            }
            .box-globe {
                min-height: 94px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                padding: 0 2em;
                text-align: center;
                p {
                    margin: 0;
                    font-size: 1.5em;
                    line-height: 28px;
                    @media (max-width: 1199px) {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }
        }
        &--light {
            .gobe-circle, .box-globe {
                background-color: var(--primary-color);
            }
        }
        &--dark {
            .gobe-circle, .box-globe {
                background-color: var(--light-txt-color);
            }
        }
    }

    .section-about {
        position: relative;
        padding: 4em 0 3em;
        background-color: white;
        overflow: hidden;
        @media (max-width: 767px) {
            padding: 2em 0;
        }
        .row-about {
            &__col {
                width: 50%;
                @media (max-width: 991px) {
                    width: 100%;
                }
                .title-section {
                    width: auto;
                    &__subhead {
                        width: 90%;
                    }
                }
                .box-img-cpragma {
                    width: 600px;
                    height: 600px;
                    background-image: url("../images/bg-c-pragma.webp");
                    background-position: 85% 95%;
                    background-size: 800px;
                    background-repeat: no-repeat;
                    background-attachment: fixed;
                    @media (max-width: 991px) {
                        display: none;
                    }

                    -webkit-mask-image: url('../images/bg-c.svg');
                    mask-image: url('../images/bg-c.svg');
                    -webkit-mask-size: cover;
                    mask-size: cover;
                }
            }
        }
    }

    .section-solutions {
        padding: 5em 0;
        background-image: url("../images/bg-c.svg");
        background-size: 900px;
        background-position: right -40% center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-color: #f4f3ed;
        @media (max-width: 767px) {
            padding: 2em 0;
            background-size: 1300px;
            background-position: 60% center;
            background-attachment: unset;
        }
        .solutions-txt-row {
            &__col {
                width: 33.33333%;
                @media (max-width: 1199px) {
                    width: 50%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }

    .section-strategy {
        position: relative;
        padding: 5em 0;
        background-color: var(--secondary-color);
        overflow: hidden;
        @media (max-width: 767px) {
            padding: 2em 0;
        }
        .title-section {
            width: auto;
        }
        .row-strategy {
            &__col {
                &--title {
                    width: 30%;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                }
                &--img {
                    width: 70%;
                    @media (min-width: 992px) {
                        padding-left: 80px;
                    }
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .circle-strategy-xs {
                        width: 100%;
                        @media (min-width: 992px) {
                            display: none;
                        }
                    }
                    .box-circle-strategy {
                        position: relative;
                        display: inline-block;
                        margin-left: 4em;
                        @media (max-width: 991px) {
                            display: none;
                        }
                        .bg-circle {
                            display: block;
                            @media (max-width: 767px) {
                                width: 280px;
                            }
                        }
                        .circle-info {
                            position: absolute;
                            &.circle-info1 {
                                top: 0;
                                left: 17px;
                            }
                            &.circle-info2 {
                                top: -22px;
                                right: 0;
                            }
                            &.circle-info3 {
                                top: 50%;
                                right: -24px;
                            }
                            &.circle-info4 {
                                bottom: -29px;
                                right: 50%;
                            }
                            &.circle-info5 {
                                left: -64px;
                                top: 40%;
                            }
                        }
                        .circle-stop {
                            position: absolute;
                            top: 0;
                            left: 50%;
                        } 
                    }
                }
            }
        }
    }

    .section-findings {
        position: relative;
        padding: 5em 0 3em;
        background-color: #F4F3ED;
        overflow: hidden;
        @media (max-width: 767px) {
            padding: 2em 0;
        }
        .row-findings {
            align-items: center;
            &__col {
                &--title {
                    width: 40%;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .title-section {
                        width: auto;
                        margin: 0;
                    }
                }
                &--info {
                    width: 60%;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .list-findings-info {
                        &__item {
                            display: flex;
                            align-items: center;
                            margin: 1em 0;
                            span {
                                display: flex;
                                align-items: center;
                                height: 56px;
                                padding: 0 2em;
                                margin-left: .5em;
                                border: 1px solid var(--secondary-color);
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
        }
        .row-insight {
            margin-top: 3em;
            margin-bottom: 1.5em;
            @media (max-width: 991px) {
                margin-top: 2em;
                margin-bottom: 0;
            }
            &__col {
                width: 25%;
                @media (max-width: 991px) {
                    flex-basis: 50%;
                    margin: 0 0 1em;
                }
                .box-insight {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: black;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(36, 63, 59, .75);
                        transition: .3s;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &__img {
                        display: block;
                        img {
                            display: block;
                            width: 100%;
                            transition: .3s;
                        }
                    }
                    &__txt {
                        display: block;
                        width: 100%;
                    }
                    &__btn {
                        position: relative;
                        display: block;
                        width: 100%;
                        margin: .5em 0 0;
                        padding: .5em 2.5em;
                        border: 1px solid var(--tertiary-color);
                        font-size: 1.25em;
                        color: var(--tertiary-color);
                        text-align: center;
                        border-radius: 50px;
                        &:hover {
                            background-color: var(--tertiary-color);
                            color: var(--primary-color);
                        }
                    }
                    h2 {
                        margin: .5em 0 0;
                        font-size: 39px;
                        font-family: var(--secondary-font);
                        font-style: italic;
                        @media (max-width: 991px) {
                            margin: .5em 0 0;
                            font-size: 32px;
                        }
                    }
                    h3 {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: .75em 0;
                        font-size: 20px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media (max-width: 991px) {
                            font-size: 18px;
                        }
                    }
                    p {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-size: 18px;
                        @media (max-width: 991px) {
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }

    .section-talk {
        position: relative;
        padding: 5em 0 10em;
        background-color: var(--secondary-color);
        @media (max-width: 767px) {
            padding: 2em 0;
        }
        .row-talk {
            align-items: center;
            &__col {
                &--title {
                    width: 45%;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .title-section {
                        width: auto;
                        margin: 0;
                    }
                }
                &--contact {
                    width: 55%;
                    @media (max-width: 991px) {
                        width: 100%;
                    }
                    .row-contact {
                        margin: 0 -30px;
                        @media (max-width: 1199px) {
                            margin: 0 -15px;
                        }
                        @media (max-width: 991px) {
                            margin: 2em 0 0;
                        }
                        &__col {
                            width: 50%;
                            padding: 20px 30px;
                            @media (max-width: 1199px) {
                                padding: 15px;
                            }
                            @media (max-width: 991px) {
                                width: 100%;
                                padding: 5px 0;
                            }
                            .btn-contact {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                min-height: 55px;
                                padding: .35em 0;
                                border: 1px solid var(--primary-color);
                                border-radius: 50px;
                                font-size: 24px;
                                color: var(--primary-color);
                                text-align: center;
                                @media (max-width: 1199px) {
                                    font-size: 20px;
                                }
                                &:hover {
                                    background-color: var(--primary-color);
                                    color: var(--secondary-color);
                                }
                            }
                            &:last-child {
                                &:after {
                                    content: "Almirante Pastene 333, Oficina 402, Providencia";
                                    display: block;
                                    margin: .5em 0 0;
                                    font-size: 15px;
                                    color: var(--primary-color);
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    &.cpragma-news {
        background-color: #F4F3ED;
        &--light {
            .news {
                .news-content {
                    &__top {
                        border-bottom-color: var(--primary-color);
                        .news-globe {
                            span {
                                background-color: var(--primary-color);
                                color: var(--secondary-color);
                            }
                        }
                        h1, h3 {
                            color: var(--primary-color);
                        }
                    }
                    &__txt {
                        p, h3 {
                            color: var(--secondary-color);
                        }
                        .footer-news {
                            a {
                                background-color: var(--primary-color);
                                color: var(--secondary-color);
                                &:hover {
                                    background-color: var(--secondary-color);
                                    color: var(--light-txt-color);
                                }
                            }
                        }
                    }
                }
            }
        }
        &--dark {
            color: var(--secondary-color);
            .news {
                .news-content {
                    &__top {
                        border-bottom-color: var(--tertiary-color);
                        .news-globe {
                            span {
                                background-color: var(--tertiary-color);
                                color: var(--light-txt-color);
                            }
                        }
                        h1, h3 {
                            color: var(--light-txt-color);
                        }
                    }
                    &__txt {
                        p, h3 {
                            color: var(--tertiary-color);
                        }
                        .footer-news {
                            a {
                                background-color: var(--tertiary-color);
                                color: var(--light-txt-color);
                                &:hover {
                                    background-color: var(--secondary-color);
                                    color: var(--light-txt-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.news {
    position: relative;
    .news-content {
        &__top {
            position: relative;
            padding: 5em 0;
            border-bottom-width: 30px;
            border-bottom-style: solid;
            background-position: center;
            background-size: cover;
            &:after {
                content: "";
                position: absolute;
                background: rgb(0,0,0);
                background: linear-gradient(0deg, rgba(0,0,0,.65) 30%, rgba(0,0,0,0) 100%); 
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
            }
            .news-globe {
                position: relative;
                display: flex;
                justify-content: flex-end;
                z-index: 1;
                span {
                    display: block;
                    padding: .35em 2em;
                    border-radius: 50px;
                    font-size: 1.125em;
                    font-family: var(--secondary-font);
                    font-style: italic;
                }
            }
            h1, h3 {
                position: relative;
                z-index: 1;
            }
            h1 {
                margin: 1em 0 0;
                font-size: 48px;
                z-index: 1;
            }
            h3 {
                margin: 1em 0 0;
                font-size: 24px;
                font-family: var(--secondary-font);
                font-style: italic;
            }
        }
        &__txt {
            padding: 3em 0;
            p {
                color: var(--secondary-color);
            }
            h3 {
                margin: .5em 0 .35em;
                font-size: 1.25em;
                color: var(--secondary-color);
            }
            .row-news {
                &__col {
                    &--full {
                        width: 100%;
                    }
                    &--l {
                        width: 70%;
                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }
                    &--s {
                        width: 30%;
                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }
            }
            .n-hilight {
                padding: 1.5em 0;
                border-top-width: 1px;
                border-top-style: solid;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                p {
                    margin: 0;
                    font-size: 14px;
                }
            }
            .n-quote {
                padding: 1.5em 0;
                p {
                    margin: 0;
                    font-size: 18px;
                    font-family: var(--secondary-font);
                    font-style: italic;
                    font-weight: 700;
                }
                span {
                    display: block;
                    padding: .35em 0 0;
                    font-size: 11px;
                    line-height: normal;
                }
            }
            .news-share {
                padding: 1em 0 0;
                ul {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                }
            }
            .footer-news {
                display: flex;
                justify-content: space-between;
                padding: 5em 0 0;
                align-items: center;
                @media (max-width: 767px) {
                    flex-direction: column;
                    padding: 3em 0 0;
                }
                a {
                    display: block;
                    padding: .5em 2em;
                    border-radius: 50px;
                    font-size: 1.25em;
                    border: none;
                    text-decoration: underline;
                }
                img {
                    display: block;
                    @media (max-width: 767px) {
                        width: 200px;
                        margin: 2em 0 0;
                    }
                }
            }
        }
    }
}